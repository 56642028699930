export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        // Scroll to section
        $('.scroll-to-section').click(function () {
            var targetElement = $(this).attr('data-scroll-target');
            if (targetElement) {
                $('html, body').animate({
                    scrollTop: $('#' + targetElement).offset().top,
                }, 'slow');
            }
        });
        menuLineAnimation();
        setTimeout(function(){
            menuLineAnimation();
        }, 1000);
        function menuLineAnimation() {
            // Active menu line animation
            var j$ = jQuery,
                $slideLine = j$('#slide-line'),
                $currentItem = j$('.header__menu > .nav > .menu-item.active');

            j$(function () {
                // Menu has active item
                if ($currentItem[0]) {
                    $slideLine.css({
                        'width': $currentItem.width(),
                        'left': $currentItem.position().left,
                    });
                }

                // Underline transition
                j$('.header__menu > .nav > .menu-item').hover(
                    // Hover on
                    function () {
                        $slideLine.css({
                            'width': j$(this).width(),
                            'left': j$(this).position().left + parseInt(j$(this).css('padding-left')),
                        });
                    },
                    // Hover out
                    function () {
                        if ($currentItem[0]) {
                            // Go back to current
                            $slideLine.css({
                                'width': $currentItem.width(),
                                'left': $currentItem.position().left,
                            });
                        } else {
                            // Disapear
                            $slideLine.width(0);
                        }
                    }
                );
            });
        }

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
